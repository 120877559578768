import axios from './app.utils';
import { BASE_URL } from '../constants/config';

export const LANGUAGE_ENDPOINTS = {
  languages: `${BASE_URL}api/languages`,
  active: `${BASE_URL}api/languages/active`,
  byId: (id) => `${BASE_URL}api/languages/${id}`,
};

const token = localStorage.getItem('authorization');

class Languages {
  // async getAllLanguages(filters = {}) {
  //   try {
  //     const params = new URLSearchParams();
  //     if (filters.active !== undefined) {
  //       params.append('active', filters.active);
  //     }
      
  //     const response = await axios.get(`${LANGUAGE_ENDPOINTS.languages}?${params.toString()}`, {
  //       headers: {
  //         authorization: token,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching languages:', error);
  //     throw error;
  //   }
  // }
  async getActiveLanguages() {
    const response = await axios.get(LANGUAGE_ENDPOINTS.active, {
      headers: {
        authorization: token,
      },
    });
    return response.data;
  }
  async getLanguageById(id) {
    const response = await axios.get(LANGUAGE_ENDPOINTS.byId(id), {
      headers: {
        authorization: token,
      },
    });
    return response.data;
  }
}

export const LanguageService = new Languages(); 